<template>

  <el-dialog title="核销码详情" :visible.sync="show" :lock-scroll="false" :close-on-click-modal="false" width="350px" :before-close="dialogClose">
    <el-form label-width="100px">
      <el-form-item label="核销码">
        {{info.pwNo}}
      </el-form-item>
      <el-form-item label="金额">
        {{info.price}} 元
      </el-form-item>
      <el-form-item label="客户名称">
        {{info.userName}}
      </el-form-item>
      <el-form-item label="客户手机">
        {{info.userPhone}}
      </el-form-item>
      <el-form-item label="使用状态">
        <span v-if="info.status == '0'">未核销</span>
        <span v-if="info.status == '1'">已核销</span>
        <span v-if="info.status == '2'">已过期</span>
        <span v-if="info.status == '3'">已撤销</span>
        <span v-if="info.status == '4'">已充值</span>
      </el-form-item>
      <el-form-item label="使用时间">
        {{info.createTime}}
      </el-form-item>
      <el-button @click="dialogClose">关闭</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'watchInfo',
  props: ['show', 'info'],
  methods: {
    dialogClose() {
      this.$emit('update:show', false)
    },
  }
}
</script>

<style>
</style>