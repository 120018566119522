
<template>
  <div>
    <el-form label-width="95px" inline size="mini">
      <el-form-item label="领取状态">
        <el-select style="width:120px;" clearable v-model="search.status" placeholder="全部">
          <el-option v-for="(item,i) in statusList" :key="i" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发放时间：">
        <el-date-picker style="width:314px;" v-model="createTime" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="模糊搜索：" label-width="120px">
        <el-input style="width:130px;" placeholder="请输入客户名称/手机号" v-model="search.search"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto">
      <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="orgName" align="center" label="所属机构"></el-table-column>
      <el-table-column prop="pwNo" align="center" label="核销码"></el-table-column>
      <el-table-column prop="price" align="center" label="面额（元）" width="100"></el-table-column>
      <el-table-column prop="userName" align="center" label="客户"></el-table-column>
      <el-table-column prop="userPhone" align="center" label="手机"></el-table-column>
      <el-table-column prop="createTime" align="center" label="发放时间" width="180"></el-table-column>
      <el-table-column align="center" label="领取状态" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.status == '0'">未核销</span>
          <span v-if="scope.row.status == '1'">已核销</span>
          <span v-if="scope.row.status == '2'">已过期</span>
          <span v-if="scope.row.status == '3'">已撤销</span>
          <span v-if="scope.row.status == '4'">已充值</span>
        </template>
      </el-table-column>
      <el-table-column align="" label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-view" @click="gotoInfo(scope.row)" style="color:#333">查看</el-button>
          <el-button icon="el-icon-refresh-left" type="text" v-if="scope.row.status==0" @click="recall(scope.row.id)">撤回</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-footer">
      <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
      </el-pagination>
      <!-- <el-button type="primary" icon="el-icon-download" size="mini" @click="handleExport">导出</el-button> -->
    </div>
    <watchInfo :show.sync="dialogInfo" :info="info" v-if="dialogInfo">
    </watchInfo>
  </div>
</template>
<script>
import watchInfo from './components/watchInfo.vue'


import { getEdaijiaGrantRecordPage, isRevoke } from '@/api/codes'
import { getCookies, auditStatusTag, exportCommon } from '@/utils/utils'
export default {
  name: 'eSubstituteDriving',
  components: {
    watchInfo
  },
  data() {
    return {
      auditStatusTag: auditStatusTag,
      search: {
        search: '',
        pageNum: 1,//分页页数
        pageSize: 10,//每页显示多少
        status: ''
      },
      statusList: [{ label: '未核销', value: '0' }, { label: '已核销', value: '1' }, { label: '已过期', value: '2' }, { label: '已撤销', value: '3' }, { label: '已充值', value: '4' }],
      createTime: ['', ''],
      list: [],
      total: 0,//分页总页数
      dialogInfo: false,
      info: {}
    }
  },
  watch: {
    isAddDialog() {
      this.getList();
    },
  },
  created() {
    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    this.getList();
  },
  methods: {
    //导出e代驾发放记录
    handleExport() {
      exportCommon("/wlynEdaijiaGrantRecord/exportEdaijiaGrantRecordExcel");
    },
    //点击撤回按钮
    recall(id) {
      let that = this;
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        isRevoke({ 'id': id })
          .then((response) => {
            if (response.code == 200) {
              this.getList()
            }
            that.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.search.pageNum = val;
      this.getList();
    },
    getList() {
      let param = Object.assign({}, this.search)
      param['receiveStartTime'] = this.createTime[0];
      param['receiveEndTime'] = this.createTime[1];
      getEdaijiaGrantRecordPage(param).then((response) => {
        if (response.code == 200) {
          this.total = response.data.total;
          this.list = response.data.records;
        }
      })
    },
    //点击查看
    gotoInfo(item) {
      this.info = item
      this.dialogInfo = true
    },
  },
}
</script>
<style lang="scss" scoped>
.pagination-footer {
  display: flex;
  justify-content: flex-end;
  padding-right: 10%;
  .el-button--primary {
    margin: 10px 0 0;
    height: 28px;
  }
}
</style>